import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  FormGroup,
  Label,
  Button,
  Spinner,
  Form,
  Table,
} from "reactstrap";

//Import Breadcrumb

//Import actions
import { getBookingDetailsRequest } from "store/actions";
import moment from "moment";
import { withTranslation } from "react-i18next";

const ViewBooking = (props) => {
  const [state, setstate] = useState({ status: "active" });
  const {
    match: { params },
    getBookingDetailsRequest,
    bookingDetails,
    error,
    loading,
  } = props;

  useEffect(() => {
    if (params && params.id) {
      getBookingDetailsRequest(params.id);
    }
  }, [params]);

  const history = useHistory();

  // Parse the ISO string with Moment.js

  return (
    <React.Fragment>
      <div className="page-content">
        <section className="viewprofile_main">
          <Container>
            <a
              href="javascript:void(0)"
              className="backto_censor"
              onClick={() => history.push("/bookings")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17.77 3.77L16 2L6 12L16 22L17.77 20.23L9.54 12L17.77 3.77Z"
                  fill="#2E3A59"
                ></path>
              </svg>
              {props.t("bookings")}
            </a>

            <div className="profile_content">
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <p>
                    <b>{props.t("booking_id")}:</b> {bookingDetails?._id}
                  </p>
                  <p>
                    <b>{props.t("booking_type")}:</b>{" "}
                    {bookingDetails?.bookingType}
                  </p>
                  <p>
                    <b>{props.t("booking_status")}:</b>{" "}
                    {bookingDetails?.bookingStatus}
                  </p>
                  <p>
                    <b>{props.t("amount")}:</b> {bookingDetails?.amount}
                  </p>
                  <p>
                    <b>
                      {props.t("user")} {props.t("email")}:
                    </b>{" "}
                    {bookingDetails?.userId.email}
                  </p>
                  <p>
                    <b>
                      {props.t("user")} {props.t("mobile")}:
                    </b>{" "}
                    {bookingDetails?.userId?.countryCode+" "+bookingDetails?.userId.mobileNumber}
                  </p>
                  <p>
                    <b>{props.t("parking")}:</b>{" "}
                    {bookingDetails?.parkingId.building_name}
                  </p>
                  <p>
                    <b>{props.t("location")}:</b>{" "}
                    {bookingDetails?.parkingId.location_name}
                  </p>
                  <p>
                    <b>{props.t("vehicle_name")}:</b>{" "}
                    {bookingDetails?.vehicalId.vehicalName}
                  </p>
                  <p>
                    <b>{props.t("vehicle_number")}:</b>{" "}
                    {bookingDetails?.vehicalId.vehicalNumber}
                  </p>
                  <p>
                    <b>{props.t("created_at")}:</b>
                    {bookingDetails?.createdAt &&
                      moment
                        .utc(bookingDetails?.createdAt)
                        .local()
                        .format("DD MMM YYYY LT")}
                  </p>
                  {/* <p><b>{props.t("start_date")}:</b> {moment(bookingDetails?.startDate).format("DD MMM YYYY")}</p> */}
                  <p>
                    <b>{props.t("start_time")}:</b>
                    {bookingDetails?.bookingStartTime &&
                      moment
                        .utc(bookingDetails?.bookingStartTime)
                        .local()
                        .format("DD MMM YYYY LT")}
                  </p>
                  {/* <p><b>{props.t("end_date")}:</b> {moment(bookingDetails?.endDate).format("DD MMM YYYY")}</p> */}
                  <p>
                    <b>{props.t("end_time")}:</b>
                    {/* {bookingDetails?.endDate &&
                      moment(bookingDetails?.endDate).format(
                        "DD MMM YYYY"
                      )}{" "} */}
                    {
                      bookingDetails?.bookingEndTime &&
                        moment
                          .utc(bookingDetails?.bookingEndTime)
                          .local()
                          .format("DD MMM YYYY LT")
                      //  moment(bookingDetails?.bookingEndTime)?.format("DD MMM YYYY LT")
                      // .utcOffset(330)
                    }
                  </p>
                  <p>
                    <b>{props.t("building_name")}:</b>{" "}
                    {bookingDetails?.parkingId?.building_name}
                  </p>
                  <p>
                    <b>{props.t("floor_no")}:</b> {bookingDetails?.floorNo}
                  </p>
                  <p>
                    <b>{props.t("slot_no")}:</b> {bookingDetails?.slotNo}
                  </p>
                  <p>
                    <b>{props.t("parking_type")}:</b>{" "}
                    {bookingDetails?.parkingId?.type}
                  </p>
                  <p>
                    <b>{props.t("sensor_status")}:</b>{" "}
                    {bookingDetails?.parkingId?.sensor_status}
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ Login, Bookings }) => ({
  user: Login.user,
  loading: Bookings?.loading,
  bookingDetails: Bookings?.bookingDetails,
});

const mapDispatchToProps = (dispatch) => ({
  getBookingDetailsRequest: (id) => dispatch(getBookingDetailsRequest(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ViewBooking));
