import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Form } from "reactstrap";
import Welcomegreet from "components/Welcomegreet";

//i18n
import { withTranslation } from "react-i18next";
import { Col, Container, Row, Spinner } from "reactstrap";
import SearchInput from "components/Common/SearchInput";
import { getCustomers, removeCustomer } from "store/actions";
import UsersColumns from "./Columns";
import UsersConfirmModal from "./ConfirmModal";

const Users = (props) => {
  const { history, users, totalUsers, onGetUsers, loading, onDeleteUser } =
    props;
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    userId: null,
  });

  const [filter, setFilter] = useState({
    orderBy: "createdAt",
    order: -1,
    page: 1,
    limit: 10,
  });
  const [searchText, setSearchText] = useState("");
  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalUsers,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    onGetUsers({ ...filter });
  }, [JSON.stringify(filter)]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText);
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (userId) => {
    setConfirmModal((prevState) => ({ isOpen: !prevState.isOpen, userId }));
  };

  const removeConfirm = () => {
    const { userId, isOpen } = confirmModal;

    const onComplete = () => {
      onGetUsers(filter);
    };

    setConfirmModal({ isOpen: !isOpen, userId: null });
    onDeleteUser({ userId: userId,status:"deleted" }, onComplete);
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText }));
  };

  const emptyDataMessage = () => {
    return <div className="text-center">{props.t("noDataFound")}</div>;
  };

  return (
    <React.Fragment>
      <UsersConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />

      <div className="page-content">
        <Container fluid>
          <Welcomegreet />
          <div className="fillter_ssection mt-5">
            <Form>
              <div className="latest_cust_table mt-4">
                {/* <Newlist/> */}

                <Row>
                  <Col xs="12">
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="_id"
                          data={users}
                          columns={UsersColumns(props.t, toggleConfirmModal)}
                          bootstrap4
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row>
                                <Col lg={6} md={12} sm={12}>
                                  <div className="custom-flex-sm d-flex align-items-center">
                                    <div className="search d-flex align-items-center">
                                      <div className="pl-3">
                                        <SearchInput
                                          {...toolkitProps.searchProps}
                                          triggerSearch={onSearch}
                                          placeholder={props.t("search")}
                                          searchText={searchText}
                                        />
                                      </div>

                                      {/* {!!filter.fieldName ||
                            (!!filter.search && (
                              <div
                                className="mr-4"
                                style={{
                                  minWidth: "73px",
                                }}
                              >
                                <Link
                                  to="#"
                                  onClick={(e) => {
                                    e.preventDefault()
                                    setFilter((prevState) => ({
                                      ...prevState,
                                      fields: {
                                        fieldName: "",
                                        fieldValue: "",
                                      },
                                      search: "",
                                    }))
                                    setSearchText("")
                                  }}
                                  className=""
                                >
                                  {props.t("clear_filters")}
                                </Link>
                              </div>
                            ))} */}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive spinner-content mt-3">
                                    <BootstrapTable
                                      responsive
                                      remote
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table table-centered table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      onTableChange={handleTableChange}
                                      noDataIndication={emptyDataMessage}
                                      {...paginationTableProps}
                                      defaultSorted={[
                                        {
                                          dataField: "createdAt",
                                          order: "desc",
                                        },
                                      ]}
                                      sort={{
                                        sortCaret: (order) =>
                                          order === "asc" ? (
                                            <span className="caret">
                                              <i className="bx bx-caret-up" />
                                            </span>
                                          ) : order === "desc" ? (
                                            <span className="caret">
                                              <i className="bx bx-caret-down" />
                                            </span>
                                          ) : null,
                                      }}
                                    />

                                    {loading && (
                                      <div className="spinner">
                                        <Spinner color="primary" />
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ customers }) => ({
  loading: customers.loading,
  users: customers.customers,
  totalUsers: customers.totalCustomers,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUsers: (data) => {
      console.log(data);
      dispatch(getCustomers(data));
    },
    onDeleteUser: (data, callback) => dispatch(removeCustomer(data, callback)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Users))
);
